.App-hero-section {
  background-color: rgb(9, 11, 17);
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.full-height {
  min-height: 100vh;
}

.box {
  height: 100px;
  width: 100px;
  background: rgb(219, 48, 62);
}

/* General styling */

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.full-height {
  min-height: 100vh;
}

.center-text {
  text-align: center;
}

/* Responsive styling */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
